<template>
	<div class="myproduct mobileproduct">

		<examineDrawer v-if="dataType == 'Read'" :mobileInfo='true' @close="examineVisible = false" :dataObj="dataObj"
			:examineVisible.sync="examineVisible" />

		<infoDrawer v-else :mobileInfo='true' @saveData="saveData" @close="dialogVisible = false"
			:allDictionaries="allDictionaries" :dialogVisible.sync="dialogVisible" :mapOptions="mapOptions"
			:dataType="dataType" :dataObj="dataObj" :ProductCategory="ProductCategory"
			:ProductRepaymentMethod="ProductRepaymentMethod" :CheckCreditScan="CheckCreditScan" />
	</div>
</template>

<script>
import {
	itemByType,
	networkAreaList,
	getUserInfoByToken
} from '@/api/index.js';
import infoDrawer from './module/infoDrawer.vue';
import examineDrawer from './module/examineDrawer.vue';
export default {
	name: 'mobileproduct',
	data() {
		return {
			dataType: '',
			dialogVisible: true,
			examineVisible: false,
			ProductCategory: [],
			ProductRepaymentMethod: [],
			CheckCreditScan: [],
			allDictionaries: {
				CompanyOrPersonal: [],
				OnlineOrOffline: [],
				MortgageOrCredit: [],
				MortgageRate: [],
				Standard: [],
				UseClassification: [],
				EnterpriseOperation: [],
				TaxClassification: [],
				InternationalRestrictions: [],
				PropertyRequirements: [],
				ProfessionalRequirements: [],
				CollateralTypes: [],
				Mortgagor: [],
				QuotaType: [],
				CollectionAccount: [],
				CrossBridge: [],
				WithdrawalRequirement: [],
				WhetherWhiteHouseholdsAdmitted: [],
				PreviousYearsLossesAllowedForAdmission: [],
				MicroloanBalanceAllowedForAdmission: [],
				PropertyRequirementActualController: []
			},
			mapOptions: [],
			dataObj: {},
		};
	},
	created() {
		this.getUserInfoByToken({ token: this.$route.query.token });
		this.dataObj.financialProductId = this.$route.query.financialProductId || '';
		this.dataType = this.$route.query.dataType || '';
		this.getNetworkAreaList();

		this.getGradeList('ProductCategory', true);
		this.getGradeList('ProductRepaymentMethod');
		this.getGradeList('CheckCreditScan');
		this.getGradeList('CompanyOrPersonal');
		this.getGradeList('OnlineOrOffline');
		this.getGradeList('MortgageOrCredit');
		this.getGradeList('MortgageRate');
		this.getGradeList('MortgageRate');
		this.getGradeList('Standard');
		this.getGradeList('UseClassification');
		this.getGradeList('EnterpriseOperation');
		this.getGradeList('TaxClassification');
		this.getGradeList('InternationalRestrictions');
		this.getGradeList('PropertyRequirements');
		this.getGradeList('ProfessionalRequirements');
		this.getGradeList('CollateralTypes');
		this.getGradeList('Mortgagor');
		this.getGradeList('QuotaType');
		this.getGradeList('CollectionAccount');
		this.getGradeList('CrossBridge');
		this.getGradeList('WithdrawalRequirement');
		this.getGradeList('WhetherWhiteHouseholdsAdmitted');
		this.getGradeList('PreviousYearsLossesAllowedForAdmission');
		this.getGradeList('MicroloanBalanceAllowedForAdmission');
		this.getGradeList('PropertyRequirementActualController');
	},
	methods: {

		async getUserInfoByToken(data) {
			const res = await getUserInfoByToken(data);
			debugger
			if (res.returncode === 0) {
				localStorage.setItem('userInfo', JSON.stringify(res));
				this.$store.commit('setUserInfo', res);
			} else {
				this.$message({
					message: res.message,
					type: 'warning',
				});
			}
		},
		async getNetworkAreaList() {
			const res = await networkAreaList();
			const myres = this.delchildren(res[0].children);
			localStorage.setItem('mapList', JSON.stringify(myres));
			this.mapOptions = myres;
		},
		delchildren(data) {
			data.forEach((item) => {
				item.vals = item.value + ',' + item.name
				if (!item.children.length || item.name.includes('市') || item.name.includes('区') || item.name.includes('县') || item.name.includes('州')) {
					delete item.children;
				} else {
					this.delchildren(item.children);
				}
			});
			return data;
		},
		async getGradeList(name, b = false) {
			const res = await itemByType({
				dictionaryType: name,
			});
			if (res.returncode === 0) {
				if (this[name]) {
					this[name] = res.data.map((item) => ({
						value: b ? item.itemValue : Number(item.itemValue),
						label: item.itemName,
					}));
				} else {
					this.allDictionaries[name] = res.data.map((item) => ({
						value: Number(item.itemValue),
						label: item.itemName,
					}));
				}
			}
		},

		saveData() {

			// this.dialogVisible = false;
		},


	},
	components: {
		infoDrawer,
		examineDrawer,
	},
};
</script>
<style lang="scss">
.myproduct {
	width: 100%;
	padding: 20px;
	padding-bottom: 100px;

}

.fy {
	text-align: end;
	padding: 10px 20px;
}

// .v-modal {
// 	display: none !important;
// }

.el-popup-parent--hidden {
	overflow: auto !important;
}

.mobileproduct .edit span {
	text-indent: 0px !important;

}

.mobileproduct .requirement {
	padding-left: 0;
}

.mobileproduct .mb {
	margin-bottom: 22px;
}

.mobileproduct .el-divider__text {
	color: #ED701F;

}

.mobileproduct .el-divider {
	background-color: #ED701F;
}

.mobileproduct p {
	color: #ED701F;
	font-weight: 800;
}

.mr50 .el-form-item__content {
	margin-left: 10px;
}

.mobileproduct .demo-drawer__footer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	background: #fff;
	padding: 10px 0;
}

.mobileproduct .botton-btn {
	position: fixed;
	bottom: 0;
	display: flex;
	width: 100%;
	height: 50px;
	left: 0;
	right: 0;
	box-shadow: 0 2px 12px rgb(100 101 102 / 12%);
	/* height: calc(36rpx + env(safe-area-inset-bottom)); */
	background: #fff;
	z-index: 1;
}

.mobileproduct .botton-btn>div:first-child {
	background: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 800;


}

.mobileproduct .botton-btn>div:first-child view {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.mobileproduct .botton-btn>div:last-child {
	text-align: center;
	font-weight: 800;
	color: #FFF;
	line-height: 50px;
	font-size: 16px;
	background-color: rgba(237, 112, 31, 1);
}

.el-cascader__dropdown {
	display: none !important;
}

.el-select-dropdown {
	display: none !important;
}

.el-cascader__tags input {
	display: none !important;
}

.mycheckbox {
	max-height: 300px;
	overflow: auto;
}

.mycheckbox label {
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;

}
</style>
